<template>
  <div class="constrain part">
    <div class="row-12 gutter-12">
      <div
        v-for="tile in tileSelection"
        :key="tile.id"
        class="md:col-4"
      >
        <Go
          :to="tile.newsbeitragpdf[0]?.link"
          class="md:col-4 flex-col"
          target="_blank"
        >
          <div class="tile h-full bg-white">
            <img class="tile-image" :src="tile.bild.large" :alt="tile.bild.alt">
            <div class="tile-content flex flex-col bg-white">
              <h3 class="tile-title h3 mb-4" v-html="tile.titel" />
              <p class="my-4 text-lg" v-html="tile.datum" />
              <div class="teasertext mb-16" v-html="tile.teasertext" />
              <div class="flex items-center mt-auto">
                <div
                  class="buttontext uppercase font-bold text-blue tracking-wider mr-4"
                  v-html="payload.weiterlesen"
                />
                <ArrowRight class="arrow" />
              </div>
            </div>
          </div>
        </Go>
      </div>
    </div>
    <div class="flex justify-center mt-24">
      <Button
        v-if="tileSelection.length < allTiles.length"
        :text="payload.weitereNewsLaden"
        class="mx-auto my-0 block"
        @click="loadMoreTiles"
      />
    </div>
  </div>
</template>

<script>

import ArrowRight from '../../assets/images/Pfeil.svg';
import Button from '../utils/Button.vue';

export default {
  components: {
    ArrowRight,
    Button,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      // allTiles: this.payload.kacheln,
      tilesToShow: 6,
    };
  },
  computed: {
    allTiles() {
      return this.payload.kacheln;
    },
    tileSelection() {
      return this.allTiles.slice(0, this.tilesToShow);
    },
  },
  mounted() {
    // console.log(this.payload);
  },
  methods: {
    loadMoreTiles() {
      if (this.tilesToShow < this.allTiles.length) {
        this.tilesToShow += 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tile {
  @include hover {
    .arrow {
      transform: translateX(0.5rem);
    }
    // .tile-title {
    //   background-size: 100% 2px;
    // }
  }
}

.arrow {
  transition: transform 0.25s ease-out;
  width: px(18);
  height: px(18);
  color: var(--color-blue);
}

.tile-image {
  height: px(250);
  max-height: px(250);
  width: 100%;
  object-fit: cover;
  @screen sm {
    height: px(400);
    max-height: px(400);
  }
}

.tile-content {
  padding: 2rem 1rem 1rem 1rem;
  height: calc(100% - 250px);
  @screen sm {
    height: calc(100% - 400px);
    padding: 3rem 2rem 2rem 2rem;
  }
}

.tile-title {
  display: inline;
  background-image: linear-gradient(var(--color-navy), var(--color-navy));
  background-repeat: no-repeat;
  background-size: 0 px(2);
  background-position: 0 95%;
  transition: background-size 0.3s ease-out;
}

.teasertext {
  color: var(--color-navy);
  &:deep(p) {
    line-height: 1.3;
  }
}

.buttontext {
  font-size: px(18);
}
</style>
